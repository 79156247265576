import React from 'react'
import "./footer.css"

export default function footer() {
    return (
        <div className="footer">
            <div className="footer-bottom">
                &copy; 2021 All Right Reserved | Developed by Ritesh 
            </div>
        </div>
    )
}
