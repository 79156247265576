import React from 'react'
import "./home.css"
import Header from "../../components/header/Header"
// import Sidebar from "../../components/sidebar/Sidebar"

// import Post from "../../components/post/Post";

export default function Home() {
    return (
        <>
            <Header />
            <div className="home">
                
                {/* <Sidebar /> */}
            </div>
        </>
    )
}
